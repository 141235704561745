<template>
  <v-row>
    <v-col cols="12" sm="2">
      <v-card dark color="accent" outlined class="pa-2" height="100%">
        <v-card-text>

          <v-row>
            <v-autocomplete
              :items="canalesInternos"
              v-model="canalesSelected"
              item-text="Nombre"
              item-value="CanalId"
              append-icon="keyboard_arrow_down"
              label="Canal"
              hint="Canales"
              persistent-hint
              multiple
            ></v-autocomplete>
          </v-row>
          <br />
          <br />

          <v-row>
            <v-autocomplete
              :items="empleados"
              :filter="customFilter"
              v-model="empleadoSelected"
              item-text="Nombre"
              item-value="EmpleadosId"
              append-icon="keyboard_arrow_down"
              label="Empleados"
              hint="Empleados"
              persistent-hint
              multiple
            ></v-autocomplete>
          </v-row>
          <br />
          <br />
          <v-row>
            <v-select
              :items="periodoOptions"
              v-model="periodoSelected"
              item-text="NombreMes"
              item-value="FechaFin"
              append-icon="keyboard_arrow_down"
              label="Periodo"
              hint="Periodo"
              persistent-hint
              hide-details
            ></v-select>
          </v-row>
          <br />
          <br />
          <v-row>
            <v-select
              :items="productosTipos"
              v-model="tipoProductoSelected"
              item-text="ProductoName"
              item-value="ProductoTipoId"
              append-icon="keyboard_arrow_down"
              label="Tipo Producto"
              hint="Dejar vacio para filtrar por todos"
              persistent-hint
              multiple
            ></v-select>
          </v-row>
            <br />
          <br />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="10">
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="processing"
      ></v-progress-linear>
      <v-tabs>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab>Gráfica</v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div ref="amchart" style="height: 45vh"></div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-alert dense type="warning" text v-show="!processing && isChartEmpty"
        >No existe información para esta gráfica</v-alert
      >
    </v-col>
  </v-row>
</template>

<script>
import apiDashboard from "@/api/dashboards";
import apiHome from '@/api/home'
import apiEmpleado from "@/api/empleados";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themesAnimated);

export default {
  props: ["tipoProductoOptions"],
  data() {
    return {
      processing: false,
      productosTipos:[],
      chartData: [],
      chart: null,
      empleadoSelected: [],
      canalesInternos:[],
      canalesSelected:[],
      periodoSelected: null,
      tipoProductoSelected: [],
      empleados:[],
      headers: [
        { text: "PERIDO", value: "Periodo" },
        { text: "ASOCIADO", value: "Asociado" },
        { text: "CANAL", value: "Canal" },
      ],

      customFilter(item, queryText) {
        const hasValue = (val) => (val != null ? val : "");
        const text = hasValue(item.Nombre);
        const query = hasValue(queryText);
        return (
          text
            .toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
        );
      },
    };
  },
  computed: {
    isChartEmpty() {
      return this.chartData.length === 0;
    },
    periodoOptions() {
      return this.$store.getters.periodos;
    },
    asociadoOptions() {
      return this.$store.getters.asociados.filter(
        (c) => this.empleadoSelected.includes(c.CanalId)
      );
    },
    canalOptions() {
      return this.$store.getters.canalesExternos;
    },
    currentUser() {
      return this.$store.getters.user;
    },
    filterEmpleado() {
      return this.currentUser.seleccion.empleados;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    generateChart(dataParam) {
      if (this.chart) {
        this.chart.dispose();
      }

      try {
        let chart = am4core.create(this.$refs.amchart, am4charts.XYChart);
        chart.colors.step = 8;
        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "#,###.##";
        chart.scrollbarX = new am4charts.XYChartScrollbar();

        let data = [];
        let incentivos = [];
        dataParam.forEach(function (row) {
          if (incentivos.indexOf(row.Incentivo) === -1) {
            incentivos.push(row.Incentivo);
          }
        });
      
        dataParam.forEach(function (d) {
          let newRow = {
            Empleado: d.Empleado,
            TotalLineas:0,
            Total: 0,
            Promedio: 0
          };
          let total = 0;
          let lineas = 0;
          let count = 0;

          incentivos.forEach(function (c) {
            let dc = dataParam.filter((f) => f.Empleado === d.Empleado && f.Incentivo === c);
            if (dc[0]) {
            
              const name = `${c}lineas`;

              newRow[c] = dc[0].Monto;
              newRow[name] = dc[0].Lineas;
              total += dc[0].Monto;
              lineas += dc[0].Lineas;
              count += 1;
            }
          });

          newRow["Total"] = total;
          newRow["TotalLineas"] = lineas;
          newRow["Promedio"] = count === 0 ? 0 : total / count;
          data.push(newRow);
        });

        chart.data = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Empleado";
        categoryAxis.title.text = "Empleado";
        categoryAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        incentivos.forEach(function (c) {

          const nameLineas = `${c}lineas`;

          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "Empleado";
          series.dataFields.valueY = c;
          series.dataFields.valueYLineas = nameLineas;
          series.name = c;
          series.stacked = true;
          series.columns.template.width = am4core.percent(80);
          series.columns.template.tooltipText = "Lineas: {valueYLineas}\n{name}: [bold]{valueY}[/]";
        });

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "Empleado";
        lineSeries.dataFields.valueY = "Total";
        lineSeries.name = "Total";
        lineSeries.nameLineas = "TotalLineas";
        lineSeries.strokeWidth = 3;
        lineSeries.strokeLinejoin = "bevel"
        var circleBullet = lineSeries.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBullet.circle.stroke = am4core.color("#fff");
        circleBullet.tooltipText = "Lineas: {TotalLineas}\n{name}: [bold]{valueY}[/]";

        //Promedio

        let lineSeriesPromedio = chart.series.push(new am4charts.LineSeries());
        lineSeriesPromedio.dataFields.categoryX = "Empleado";
        lineSeriesPromedio.dataFields.valueY = "Promedio";
        lineSeriesPromedio.name = "Promedio";
        lineSeriesPromedio.strokeWidth = 3;
        var circleBulletPromedio = lineSeriesPromedio.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBulletPromedio.circle.stroke = am4core.color("#fff");
        circleBulletPromedio.tooltipText = "{name}: [bold]{valueY}[/]";

        this.chartIncentivoIndividual = chart;

        this.chart = chart;
      } catch (e) {
        // empty
      }
    },
    refresh() {
      if (this.periodoSelected) {
        this.processing = true;
        let param = {
          FechaFin: this.periodoSelected,
          TiposProductos: this.tipoProductoSelected,
          EmpleadosId: this.empleadoSelected,
        };

        apiDashboard
          .getChartComisionesPorEmpleados(param)
          .then((response) => {
            this.generateChart(response.data);
            this.processing = false;
          })
          .catch((error) => {
            this.setError(error);
            this.processing = false;
          });
      }
    },
    getProductosTipos(){
      apiDashboard
          .getProductoTipos()
          .then((response) => {
            this.productosTipos = response.data
          })
          .catch((error) => {
            this.setError(error);
          });
    },
    async getCanalesInternos(){
      const responseCanales =  await apiHome.getCanalesInternos()
      
      var canales = responseCanales.data;

      this.canalesInternos = canales;
    },
    async getEmpleados(){

      var empleados = [];

      for (let i = 0; i < this.canalesSelected.length; i++) {
        const canalId = this.canalesSelected[i];

        const response = await apiEmpleado.getEmpleadosByCanal(canalId);

        empleados = empleados.concat(response.data)
      }

      this.empleados = empleados;
    }
  },
  watch: {
    canalesSelected: function () {
      this.getEmpleados();
    },
    empleadoSelected: function () {
      this.refresh();
    },
    periodoSelected: function () {
      this.refresh();
    },
    tipoProductoSelected: function () {
      this.refresh();
    },
   
    canalOptions: function () {
      if (this.canalOptions.length > 0) {
        this.empleadoSelected = [];
      }
    },
    periodoOptions: function () {
      if (this.periodoOptions.length > 0) {
        this.periodoSelected = this.periodoOptions[0].FechaFin;
      }
    },
  },
  mounted() {

    this.tipoProductoSelected = [];
    this.empleadoSelected = [];

    if (this.periodoOptions.length > 0) {
      this.periodoSelected = this.periodoOptions[0].FechaFin;
    }
 
    this.getProductosTipos();
    this.refresh();
    this.getCanalesInternos();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
</style>